<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <!--      TODO needs image-->
      <p class="mb-3">
        Rank the given compounds according to their polarity from lowest to highest.
      </p>

      <drag-to-rank-input
        v-model="inputs.studentAnswer"
        :items="options"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="Lowest"
        append-text="Highest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'ChemUCI51LB_A8Q5',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: [],
      },
      options: ['A', 'B', 'C', 'D'],
    };
  },
};
</script>
